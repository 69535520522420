exports.components = {
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-apparel-index-jsx": () => import("./../../../src/pages/apparel/index.jsx" /* webpackChunkName: "component---src-pages-apparel-index-jsx" */),
  "component---src-pages-computer-schoo-index-jsx": () => import("./../../../src/pages/computer-schoo/index.jsx" /* webpackChunkName: "component---src-pages-computer-schoo-index-jsx" */),
  "component---src-pages-contact-thanks-index-jsx": () => import("./../../../src/pages/contact/thanks/index.jsx" /* webpackChunkName: "component---src-pages-contact-thanks-index-jsx" */),
  "component---src-pages-esports-index-jsx": () => import("./../../../src/pages/esports/index.jsx" /* webpackChunkName: "component---src-pages-esports-index-jsx" */),
  "component---src-pages-gallery-index-jsx": () => import("./../../../src/pages/gallery/index.jsx" /* webpackChunkName: "component---src-pages-gallery-index-jsx" */),
  "component---src-pages-hair-nail-index-jsx": () => import("./../../../src/pages/hair-nail/index.jsx" /* webpackChunkName: "component---src-pages-hair-nail-index-jsx" */),
  "component---src-pages-menu-index-jsx": () => import("./../../../src/pages/menu/index.jsx" /* webpackChunkName: "component---src-pages-menu-index-jsx" */),
  "component---src-pages-recruit-recruit-01-index-jsx": () => import("./../../../src/pages/recruit/recruit01/index.jsx" /* webpackChunkName: "component---src-pages-recruit-recruit-01-index-jsx" */),
  "component---src-pages-recruit-recruit-02-index-jsx": () => import("./../../../src/pages/recruit/recruit02/index.jsx" /* webpackChunkName: "component---src-pages-recruit-recruit-02-index-jsx" */),
  "component---src-pages-recruit-recruit-03-index-jsx": () => import("./../../../src/pages/recruit/recruit03/index.jsx" /* webpackChunkName: "component---src-pages-recruit-recruit-03-index-jsx" */),
  "component---src-pages-recruit-recruit-04-index-jsx": () => import("./../../../src/pages/recruit/recruit04/index.jsx" /* webpackChunkName: "component---src-pages-recruit-recruit-04-index-jsx" */),
  "component---src-pages-recruit-recruit-05-index-jsx": () => import("./../../../src/pages/recruit/recruit05/index.jsx" /* webpackChunkName: "component---src-pages-recruit-recruit-05-index-jsx" */),
  "component---src-pages-recruit-recruit-06-index-jsx": () => import("./../../../src/pages/recruit/recruit06/index.jsx" /* webpackChunkName: "component---src-pages-recruit-recruit-06-index-jsx" */),
  "component---src-pages-recruit-recruit-07-index-jsx": () => import("./../../../src/pages/recruit/recruit07/index.jsx" /* webpackChunkName: "component---src-pages-recruit-recruit-07-index-jsx" */),
  "component---src-pages-recruit-recruit-08-index-jsx": () => import("./../../../src/pages/recruit/recruit08/index.jsx" /* webpackChunkName: "component---src-pages-recruit-recruit-08-index-jsx" */),
  "component---src-pages-recruit-recruit-09-index-jsx": () => import("./../../../src/pages/recruit/recruit09/index.jsx" /* webpackChunkName: "component---src-pages-recruit-recruit-09-index-jsx" */),
  "component---src-pages-recruit-recruit-10-index-jsx": () => import("./../../../src/pages/recruit/recruit10/index.jsx" /* webpackChunkName: "component---src-pages-recruit-recruit-10-index-jsx" */),
  "component---src-pages-recruit-recruit-11-index-jsx": () => import("./../../../src/pages/recruit/recruit11/index.jsx" /* webpackChunkName: "component---src-pages-recruit-recruit-11-index-jsx" */),
  "component---src-pages-recruit-top-index-jsx": () => import("./../../../src/pages/recruit-top/index.jsx" /* webpackChunkName: "component---src-pages-recruit-top-index-jsx" */),
  "component---src-pages-sdgs-index-jsx": () => import("./../../../src/pages/sdgs/index.jsx" /* webpackChunkName: "component---src-pages-sdgs-index-jsx" */),
  "component---src-pages-smartphone-school-index-jsx": () => import("./../../../src/pages/smartphone-school/index.jsx" /* webpackChunkName: "component---src-pages-smartphone-school-index-jsx" */),
  "component---src-pages-staff-index-jsx": () => import("./../../../src/pages/staff/index.jsx" /* webpackChunkName: "component---src-pages-staff-index-jsx" */),
  "component---src-templates-0-article-index-jsx": () => import("./../../../src/templates/0_article/index.jsx" /* webpackChunkName: "component---src-templates-0-article-index-jsx" */),
  "component---src-templates-1-top-index-jsx": () => import("./../../../src/templates/1_top/index.jsx" /* webpackChunkName: "component---src-templates-1-top-index-jsx" */),
  "component---src-templates-2-about-index-jsx": () => import("./../../../src/templates/2_about/index.jsx" /* webpackChunkName: "component---src-templates-2-about-index-jsx" */),
  "component---src-templates-3-service-index-jsx": () => import("./../../../src/templates/3_service/index.jsx" /* webpackChunkName: "component---src-templates-3-service-index-jsx" */),
  "component---src-templates-4-blog-index-jsx": () => import("./../../../src/templates/4_blog/index.jsx" /* webpackChunkName: "component---src-templates-4-blog-index-jsx" */),
  "component---src-templates-5-privacy-index-jsx": () => import("./../../../src/templates/5_privacy/index.jsx" /* webpackChunkName: "component---src-templates-5-privacy-index-jsx" */),
  "component---src-templates-6-contact-index-jsx": () => import("./../../../src/templates/6_contact/index.jsx" /* webpackChunkName: "component---src-templates-6-contact-index-jsx" */)
}

